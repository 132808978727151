export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';

export const MY_INFO_FETCH = 'MY_INFO_FETCH';
export const MY_INFO_SUCCESS = 'MY_INFO_SUCCESS';
export const MY_INFO_FAIL = 'MY_INFO_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const IS_AUTHENTICATED_CHECKING = 'IS_AUTHENTICATED_CHECKING';
export const IS_AUTHENTICATED_CHECK_DONE = 'IS_AUTHENTICATED_CHECK_DONE';